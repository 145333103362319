/* eslint-disable no-unused-vars */
import { message } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/generate-qr/actions'
import { serviceGenerate } from 'src/services/api/generates'

function load (payload) {
  return setState({ loading: payload })
}

function success (payload) {
  return setState({ success: payload })
}

function setState (payload) {
  return put({
    type: 'generate/set_state',
    payload
  })
}

export function * createGenerateQR ({ payload }) {
  yield load(true)
  const { token, items } = payload
  const response = yield call(serviceGenerate.generate, token, {
    params: items
  })

  if (response.status === 202) {
    message.success(response?.data?.message)
    yield put({
      type: 'generate/get_generate_qr',
      payload: {
        token,
        success: true
      }
    })
  } else {
    yield load(false)
    message.error(response?.data?.message)
  }
}

export function * updateGenerateQR ({ payload }) {
  yield load(true)

  const { token, items, generateId } = payload

  const response = yield call(serviceGenerate.update, token, {
    generateId,
    data: items
  })

  if (response.status === 202) {
    message.success(response?.data?.message)
    yield put({
      type: 'generate/get_generate_qr',
      payload: {
        token,
        success: true
      }
    })
  } else {
    yield load(false)
    message.error(response?.data?.message)
  }
}

export function * updateRequestStatus ({ payload }) {
  yield success(true)
  const { token, generateId, data } = payload
  const response = yield call(serviceGenerate.update, token, {
    generateId,
    data
  })

  if (response.status === 202) {
    yield success(false)
    message.success('Data berhasil diubah')
  } else {
    yield success(false)
    message.error(response?.data?.message)
  }
}

export function * createRequestPrint ({ payload }) {
  yield success(true)
  yield load(true)
  const { token, items } = payload
  const response = yield call(serviceGenerate.printRequest, token, {
    data: items
  })

  if (response.status === 201) {
    yield success(false)
    yield load(false)
    message.success('Permintaan berhasil diajukan')
  } else {
    yield success(false)
    yield load(false)
    message.error(response?.data?.message)
  }
}

export function * getGenerateQR ({ payload }) {
  yield load(true)
  const { params, token, success } = payload

  const response = yield call(serviceGenerate.get, token, params)
  if (response.status === 200) {
    yield setState({
      loading: false,
      data: response.data.data.items,
      meta: response.data.data.meta,
      success
    })
  } else yield load(false)
}

export function * downloadGenerateQR ({ payload }) {
  yield load(true)
  const { token, generateId } = payload
  const response = yield call(serviceGenerate.download, token, { generateId })

  if (response.status === 200) {
    yield load(false)
    if (response.data.data) {
      yield setState({
        loading: false,
        data: response.data.data
      })
    }
  } else yield load(false)
}

export function * detailGenerateQR ({ payload }) {
  yield load(true)
  const { token, generateId } = payload

  const response = yield call(serviceGenerate.detail, token, { generateId })
  if (response.status === 200) {
    // console.log(response);
    const datas = response.data.data
    const qrcodes = datas.qrcodes
    if (qrcodes) {
      datas.qrcodes = qrcodes.sort(
        (a, b) =>
          Number(
            a.sku.substring(a.sku.indexOf('-') + 1, a.sku.lastIndexOf('-'))
          ) -
          Number(
            b.sku.substring(b.sku.indexOf('-') + 1, b.sku.lastIndexOf('-'))
          )
      )
    }
    yield setState({
      loading: false,
      detailGenerate: datas
    })
  } else yield load(false)
}

export function * putUpdateGenerateQR ({ payload }) {
  yield load(true)

  const { token, items, generateId } = payload

  const response = yield call(serviceGenerate.put_update, token, {
    generateId,
    data: items
  })

  if (response.status === 202) {
    yield success(true)

    message.success('Success update data!')

    yield put({
      type: 'generate/get_generate_qr',
      payload: {
        token,
        success: true
      }
    })
  } else {
    yield load(false)

    message.error(response?.data?.message)
  }
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.create_generate_qr, createGenerateQR),
    takeEvery(actions.update_generate_qr, updateGenerateQR),
    takeEvery(actions.download_generate_qr, downloadGenerateQR),
    takeEvery(actions.detail_generate_qr, detailGenerateQR),
    takeEvery(actions.get_generate_qr, getGenerateQR),
    takeEvery(actions.print_request, createRequestPrint),
    takeEvery(actions.update_request, updateRequestStatus),
    takeEvery(actions.put_update_generate_qr, putUpdateGenerateQR)
  ])
}
