import React from 'react'
import PropTypes from 'prop-types'
import { Select, Col, DatePicker, Form, Input } from 'antd'

export const FormItem = ({
  col,
  label,
  className,
  name,
  disabled,
  upload,
  password,
  dependencies,
  inputClass,
  placeholder,
  hasFeedback,
  textarea,
  rules,
  value,
  initialValue,
  extra,
  mode,
  date,
  prefix,
  select,
  switchComponents,
  style,
  inputStyle,
  onChange,
  children
}) => {
  const selected = (
    <Select
      size='large'
      mode={mode}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
    >
      {children}
    </Select>
  )

  const textArea = (
    <Input.TextArea
      size='large'
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  )

  const datePicker = (
    <DatePicker
      value={value}
      size='large'
      onChange={onChange}
      style={{ width: '100%' }}
      placeholder={placeholder}
    />
  )

  const fieldPassword = (
    <Input.Password
      size='large'
      prefix={prefix}
      className={inputClass}
      placeholder={placeholder}
      onChange={onChange}
    />
  )
  const input = textarea
    ? (
        textArea
      )
    : date
      ? (
          datePicker
        )
      : select
        ? (
            selected
          )
        : upload
          ? (
              children
            )
          : password
            ? (
                fieldPassword
              )
            : switchComponents
              ? null
              : (
                <Input
                  disabled={disabled}
                  size='large'
                  value={value}
                  prefix={prefix}
                  className={inputClass}
                  placeholder={placeholder}
                  onChange={onChange}
                  style={inputStyle}
                />
                )
  return (
    <Col span={col || 24}>
      <Form.Item
        className={className}
        label={label}
        extra={extra}
        name={name}
        dependencies={dependencies}
        rules={rules || null}
        hasFeedback={hasFeedback}
        initialValue={initialValue}
        style={style}
      >
        {input || children}
      </Form.Item>
    </Col>
  )
}

FormItem.propTypes = {
  hasFeedback: PropTypes.bool,
  col: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  extra: PropTypes.string,
  dependencies: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  password: PropTypes.bool,
  placeholder: PropTypes.string,
  textarea: PropTypes.bool,
  upload: PropTypes.bool,
  rules: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  inputClass: PropTypes.string,
  prefix: PropTypes.object,
  date: PropTypes.bool,
  select: PropTypes.bool,
  switchComponents: PropTypes.bool,
  style: PropTypes.object,
  inputStyle: PropTypes.object,
  mode: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}
