import { all, call, put, takeEvery } from 'redux-saga/effects'
import { message, notification } from 'antd'
import { actions } from 'src/redux/scan-qr/actions'
import { serviceScanQR } from 'src/services/api/scanQR'
import { serviceCoupon } from 'src/services/api/coupons'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'scan_qr/set_state',
    payload
  })
}

function setCouponState (payload) {
  return put({
    type: 'coupon/set_state',
    payload
  })
}

export function * getScanQr ({ payload }) {
  yield load(true)

  const { qrid, body } = payload

  const response = yield call(serviceScanQR.get, qrid, body)

  if (response.status === 200) {
    yield setState({
      type: 'scan_qr/get_scan_qr',
      data: response.data.data,
      loading: false
    })
  } else yield setState({ loading: false, data: response.data })
}

export function * getScanQrProduct ({ payload }) {
  yield load(true)

  const { qrid, body } = payload

  const response = yield call(serviceScanQR.getProduct, qrid, body)

  let redirect = 'default'
  let qrContact = false

  if (body.coupon) {
    const checkCoupon = yield call(serviceCoupon.check, {
      data: {
        qrcodeId: qrid,
        coupon: body?.coupon
      }
    })

    redirect = checkCoupon?.data?.data?.redirect
    qrContact = checkCoupon?.data?.data?.qrContact

    if (checkCoupon.status !== 200) {
      yield setCouponState({
        dataCoupon: null
      })

      message.error(checkCoupon?.data.message)
      return
    }
  }

  if (response.status === 200) {
    if (body.uniqueCode) {
      openNotification(
        'success',
        'Success',
        'Coupon has been claimed',
        'topRight'
      )
    }

    if (body.coupon) {
      let number = response.data.data?.product?.phone
      const first = number[0]

      if (first === '0') {
        number = number.replace(first, '62')
      }

      const desc = 'You will be redirected in a few seconds.'

      openNotification(
        'success',
        'Coupon Successfully Claimed',
        desc,
        'topRight'
      )

      const params = {
        couponName: body.name,
        qrContact,
        redirectUrl: redirect,
        trackId: response.data.data?.trackId
      }

      const href = new URL(`${process.env.REACT_APP_FE_V2_BASE_URL}coupon/claimed`)

      Object.entries(params).forEach(([key, value]) => {
        href.searchParams.append(key, value)
      })

      setTimeout(() => {
        window.location.href = href.toString()
      }, 3000)
    }

    // kondisi sku khusus (jangan sampai terhapus)
    const skuQR = response.data?.data?.sku
    const firstPart = skuQR.split('-')[0]
    const lastPart = skuQR.split('-')[2]
    const middlePart = parseInt(skuQR.split('-')[1], 10)

    // jika darusyifa
    if (
      firstPart === 'Dsi001' &&
      lastPart === '00001' &&
      middlePart >= '30301' &&
      middlePart <= '106200'
    ) {
      response.data.data.displayMode = 'simple'
    } else if (
      firstPart === 'MHP' &&
      lastPart === '1kg' &&
      middlePart >= '0001' &&
      middlePart <= '10500'
    ) {
      // jika alyaman
      response.data.data.displayMode = 'full'
    }

    yield setState({
      type: 'scan_qr/get_scan_qr_product',
      data: response.data.data,
      loading: false
    })
  } else {
    openNotification(
      'error',
      response.data.error,
      response.data.message,
      'topRight'
    )

    yield setState({
      loading: false,
      message: response.data.message,
      statusCode: response.data.statusCode
    })
  }
}

export function * getScanQrSku ({ payload }) {
  yield load(true)
  const { sku } = payload

  const response = yield call(serviceScanQR.getBySku, sku)

  if (response.status === 202) {
    yield setState({
      type: 'scan_qr/get_scan_qr_sku',
      data: response.data.data,
      loading: false
    })
  } else yield setState({ loading: false, data: response.data })
}

export function * getScanQrUnique ({ payload }) {
  yield load(true)
  const { qrid, uniqueId } = payload

  const response = yield call(serviceScanQR.get, qrid, uniqueId)
  if (response.status === 200) {
    yield setState({
      type: 'scan_qr/get_scan_qr_unique',
      dataUnique: response.data.data,
      loading: false
    })
  } else yield setState({ loading: false, data: response.data })
}

export function * checkUnique ({ payload }) {
  yield load(true)

  const { body } = payload

  const response = yield call(serviceScanQR.checkId, body)

  if (response.status === 200) {
    yield setState({
      type: 'scan_qr/check_unique',
      dataUnique: response.data.data,
      loading: false
    })

    message.success('Berhasil memasukan Unique ID')
  } else {
    yield setState({ loading: false })

    message.error('Unique ID yang anda masukan tidak sesuai, coba lagi..')
  }
}

export function * getBatch ({ payload }) {
  yield load(true)

  const { qrcodeId } = payload

  const response = yield call(serviceScanQR.getBatch, qrcodeId)

  if (response.status === 200) {
    yield setState({
      type: 'scan_qr/get_batch',
      dataBatch: response.data.data,
      loading: false
    })
  } else {
    yield setState({ loading: false })

    message.error(response.data.message)
  }
}

export function * moveBatch ({ payload }) {
  yield load(true)

  const { body } = payload

  const response = yield call(serviceScanQR.moveBatch, body)

  if (response.status === 200) {
    yield setState({
      type: 'scan_qr/move_batch',
      dataBatch: response.data.data,
      loading: false
    })

    message.success('Batch successfully moved')
  } else {
    yield setState({ loading: false })

    message.error(response.data.message)
  }
}

const openNotification = (type, message, description, placement) => {
  notification[type]({
    message,
    description,
    placement
  })
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_scan_qr, getScanQr),
    takeEvery(actions.get_scan_qr_unique, getScanQrUnique),
    takeEvery(actions.get_scan_qr_product, getScanQrProduct),
    takeEvery(actions.get_scan_qr_sku, getScanQrSku),
    takeEvery(actions.check_unique, checkUnique),
    takeEvery(actions.get_batch, getBatch),
    takeEvery(actions.move_batch, moveBatch)
  ])
}
