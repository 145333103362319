import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Form, Modal, Row, Select } from 'antd'
import {
  TagsOutlined,
  PlusCircleOutlined,
  ProfileOutlined
} from '@ant-design/icons'

import { FormItem } from 'src/components/molecules/FormItem'
import { rules } from 'src/utils/rules'
import { countQRItem, limitScanItem } from 'src/utils/options'

export const _CreateGenerateQR = ({
  settings,
  product,
  distros,
  token,
  dispatch,
  loading,
  visible,
  onCancel
}) => {
  const [form] = Form.useForm()
  const [customCount, setCustomCount] = useState(false)
  const dataDisplayed = [
    {
      value: 'full',
      name: 'Full Data'
    },
    {
      value: 'simple',
      name: 'Simple Data'
    }
  ]

  const exportAs = [
    {
      value: 'excel',
      name: 'Excel (spreadsheets)'
    },
    {
      value: 'pdf',
      name: 'PDF'
    }
  ]

  useEffect(() => {
    loading && form.resetFields()
  }, [loading])

  const onHandleCreateGenerateQR = () => {
    form.validateFields().then((data) => {
      if (data.total === 'Custom') data.total = data.customCountQR
      if (data.limitCheck === 'Unlimited - unique id') {
        delete data.limitCheck
      }

      const items = {
        ...data,
        batchSize: Number(data.batchSize),
        total: Number(data.total),
        limitCheck: Number(data.limitCheck)
      }

      dispatch({
        type: 'generate/create_generate_qr',
        payload: {
          token,
          items
        }
      })
    })
  }

  const showCustomCountQr = (val) => {
    setCustomCount(val === 'Custom')
  }

  return (
    <Modal
      title='Create Generate QR'
      visible={visible}
      footer={false}
      onCancel={onCancel}
      width={800}
      forceRender
    >
      <Form form={form} onFinish={onHandleCreateGenerateQR} layout='vertical'>
        <Row gutter={16}>
          <FormItem
            label='Production Code'
            placeholder='Input your production code'
            name='productionCode'
            prefix={<TagsOutlined />}
            rules={[rules.required]}
          />
        </Row>
        <Row>
          <FormItem
            label='Distro'
            placeholder='Choose distro agent'
            name='distroIds'
            select
            mode='multiple'
          >
            {distros?.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </FormItem>
        </Row>
        <Row gutter={16}>
          <FormItem
            col={12}
            label='Product'
            placeholder='Choose product'
            name='productId'
            select
            rules={[rules.required]}
          >
            {product?.map((item, index) => (
              <Select.Option key={index} value={item?.id}>
                {item?.pending === true
                  ? item?.name + ' (pending)'
                  : item?.name}
              </Select.Option>
            ))}
          </FormItem>
          <FormItem
            label='Count QR'
            placeholder='Choose total count qr'
            name='total'
            select
            col={6}
            rules={[rules.required]}
            onChange={(e) => showCustomCountQr(e)}
          >
            {countQRItem?.map((item, index) => (
              <Select.Option key={index} value={item}>
                {item}
              </Select.Option>
            ))}
          </FormItem>
          <FormItem
            label='Batch Size'
            placeholder='Input batch size'
            name='batchSize'
            number
            col={6}
            initialValue={1}
            prefix={<ProfileOutlined />}
            rules={[rules.required, rules.numeric, rules.maxValue(2000)]}
          />
        </Row>
        {customCount && (
          <Row gutter={16}>
            <FormItem
              label='Custom Count QR'
              placeholder='Input custom count qr'
              name='customCountQR'
              rules={[rules.required]}
            />
          </Row>
        )}
        <Row gutter={16}>
          <FormItem
            col={12}
            label='Limit Scan'
            placeholder='Choose limit scan'
            name='limitCheck'
            select
            rules={[rules.required]}
          >
            {limitScanItem?.map((item, index) => (
              <Select.Option key={index} value={item}>
                {item}
              </Select.Option>
            ))}
          </FormItem>
          <FormItem
            col={12}
            label='Setting QR'
            placeholder='Choose name setting qr'
            name='qrCodeSettingId'
            select
            rules={[rules.required]}
          >
            {settings?.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </FormItem>
        </Row>
        <Row gutter={16}>
          <FormItem
            col={12}
            date
            label='Production Date'
            name='productionDate'
            placeholder='Select production date'
            rules={[rules.required]}
          />
          <FormItem
            col={12}
            label='Data to be displayed'
            placeholder='Choose display mode'
            name='displayMode'
            select
            rules={[rules.required]}
          >
            {dataDisplayed?.map((item, index) => (
              <Select.Option key={index} value={item.value}>
                {item.name}
              </Select.Option>
            ))}
          </FormItem>
        </Row>
        <Row gutter={16}>
          <FormItem
            col={12}
            label='Code'
            name='customCode'
            placeholder='Input your custom code'
            inputStyle={{ padding: '10px' }}
          />
          <FormItem
            col={12}
            label='Export as'
            placeholder='Choose'
            name='exportAs'
            select
            initialValue={exportAs[0].value}
            rules={[rules.required]}
          >
            {exportAs?.map((item, index) => (
              <Select.Option key={index} value={item.value}>
                {item.name}
              </Select.Option>
            ))}
          </FormItem>
        </Row>
        <Row justify='end'>
          <Button
            type='primary'
            htmlType='submit'
            loading={loading}
            icon={<PlusCircleOutlined />}
          >
            Submit
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}

const mapStateToProps = ({ reducerGenerateQR }) => {
  return { ...reducerGenerateQR }
}

export const CreateGenerateQR = connect(mapStateToProps)(_CreateGenerateQR)

_CreateGenerateQR.propTypes = {
  loading: PropTypes.bool,
  product: PropTypes.array,
  distros: PropTypes.array,
  settings: PropTypes.array,
  token: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  dispatch: PropTypes.func
}
