/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Button,
  Form,
  Row,
  Upload,
  Space,
  Col,
  Modal,
  Input,
  message,
  Card,
  Select,
  Divider
} from 'antd'
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  ShoppingCartOutlined,
  FileProtectOutlined,
  InstagramOutlined,
  FontSizeOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  CameraOutlined,
  PhoneOutlined,
  PlusOutlined,
  InboxOutlined,
  MailOutlined,
  SendOutlined,
  UserOutlined,
  LinkOutlined
} from '@ant-design/icons'
import { Forms } from 'src/components/organizes/Forms'

import { DefaultLayout } from 'src/layouts/default'
import { FormItem } from 'src/components/molecules/FormItem'
import { rules } from 'src/utils/rules'
import { PopUpFinished } from 'src/components/molecules/PopUpFinished'
import { AButton } from 'src/components/atoms/Button'
import { Gap } from 'src/components/atoms/Gap'
import { useLocation, useNavigate } from 'react-router-dom'
import Text from 'antd/lib/typography/Text'

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

const _CreateProduct = ({ popupSuccess, token, dispatch, loading }) => {
  const inputRef = useRef(null)
  const [form] = Form.useForm()
  const [formSosmed] = Form.useForm()
  const [sosmed, setSosmed] = useState([])
  const [sosmedOption, setSosmedOption] = useState(['Facebook', 'Twitter', 'Tiktok', 'Instagram', 'Youtube'])
  const [visible, setVisible] = useState(false)
  const [attr, setAttr] = useState(false)
  const category = useLocation().state
  let index = 0

  const navigation = useNavigate()
  const [display, setDisplay] = useState(false)
  const [other, setOther] = useState('')

  const onChangeOther = (event) => {
    setOther(event.target.value)
  }

  const onHandleCreteProduct = () => {
    const role = localStorage.getItem('role')

    form.validateFields().then(data => {
      const attributes = []

      if (category === 'fashion') {
        attributes.push({
          key: 'model',
          value: data.models ? attributesToCapitalize(data.models.split(/, |,/)) : '-'
        })

        attributes.push({
          key: 'size',
          value: data.sizes ? attributesToCapitalize(data.sizes.split(/, |,/)) : '-'
        })

        attributes.push({
          key: 'material',
          value: data.materials ? attributesToCapitalize(data.materials.split(/, |,/)) : '-'
        })

        attributes.push({
          key: 'color',
          value: data.colors ? attributesToCapitalize(data.colors.split(/, |,/)) : '-'
        })
      }

      const sku = data.code1 + '-' + data.code2

      delete data.code1
      delete data.code2

      const limitationData = {
        ...data,
        category,
        sosmed,
        sku: sku
      }

      if (attributes.length > 0) {
        dispatch({
          type: 'product_master/create_product_master',
          payload: {
            token,
            limitationData: {
              ...limitationData,
              attributes
            }
          }
        })
      } else {
        dispatch({
          type: 'product_master/create_product_master',
          payload: {
            token,
            limitationData
          }
        })
      }

      // } else {
      //   setDisplay(true)
      // }
    })
  }

  const addItem = (e) => {
    e.preventDefault()
    setSosmedOption([...sosmedOption, other || `New item ${index++}`])

    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  useEffect(() => {
    category == null && navigation('/dashboard/product/')
  })

  const checkHasBpom = (data) => {
    if (data !== undefined) {
      const res = data.map((item, index) => {
        const result = item.type.toLowerCase() === 'bpom' && true
        return result
      })
      return res
    } else return false
  }

  const attributesToCapitalize = (data) => {
    return data.map((item) => {
      const firstLetter = item.charAt(0)
      return firstLetter.toUpperCase() + item.slice(1)
    })
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

    !isJpgOrPng && message.error('You can only upload JPG/PNG file!')

    const isLt2M = file.size / 1024 / 1024 < 2

    !isLt2M && message.error('Image must smaller than 2MB!')
    // console.log(file)

    return isJpgOrPng && isLt2M
  }

  const onHandleSubmitSosmed = async () => {
    await formSosmed.validateFields()
      .then(data =>
        setSosmed(data)
      )
    setVisible(!visible)
  }

  const onClickCreateNew = async () => {
    await dispatch({
      type: 'product_master/set_state',
      payload: { popupSuccess: false }
    })
    form.resetFields()
  }

  const onClickCheckList = async () => {
    await dispatch({
      type: 'product_master/set_state',
      payload: { popupSuccess: false }
    })
    navigation(-1)
  }

  // const addAttribute = () => {
  //   setAttr(!attr)
  //   form.setFieldsValue({
  //     models: null,
  //     sizes: null,
  //     materials: null,
  //     colors: null
  //   })
  // }

  return (
    <DefaultLayout>
      <Forms
        form={form}
        onFinish={onHandleCreteProduct}
        titleBar='Create Product'
      >
        <Card>
          <Row gutter={16} className='align-items-center'>
            <FormItem
              rules={[rules.required]}
              prefix={<UserOutlined />}
              col={12}
              name='name'
              label='Product Name'
              placeholder='Type your product product name'
            />
            <FormItem
              rules={[rules.required, rules.min(3), rules.max(100)]}
              label='SKU'
              name='code1'
              col={4}
              prefix={<FileProtectOutlined className='text-white d-none' />}
            />
            <Text>-</Text>
            <FormItem
              label=' '
              col={3}
              disabled
              prefix={<FileProtectOutlined className='text-light' />}
            />
            <Text>-</Text>
            <FormItem
              rules={[rules.required, rules.min(3), rules.max(100)]}
              label=' '
              name='code2'
              col={4}
              prefix={<FileProtectOutlined className='text-white d-none' />}
            />
          </Row>
          <Row gutter={16}>
            <FormItem
              rules={[rules.required]}
              prefix={<FileProtectOutlined />}
              label='Distributed by'
              name='distributedBy'
              placeholder='Type your product distrubted by'
            />
          </Row>
          <Row gutter={16}>
            <FormItem
              rules={[rules.required]}
              prefix={<FileProtectOutlined />}
              col={12}
              label='Production by'
              name='producedBy'
              placeholder='Type your product production by'
            />
            <FormItem
              rules={[rules.required]}
              prefix={<ShoppingCartOutlined />}
              col={12}
              name='price'
              label='Price'
              placeholder='Type your product price'
            />
          </Row>
          <Row gutter={16} align='middle'>
            <FormItem
              rules={[rules.required]}
              prefix={<PhoneOutlined />}
              label='Number Phone'
              name='phone'
              placeholder='Type your product number'
            />
          </Row>
          <Row gutter={16}>
            <FormItem
              rules={[rules.required]}
              prefix={<InboxOutlined />}
              col={12}
              textarea
              name='shortDescription'
              label='Short Description'
              placeholder='Type your product short description'
            />
            <FormItem
              prefix={<MailOutlined />}
              col={12}
              textarea
              name='description'
              label='Full Description'
              placeholder='Type your product full description'
            />
          </Row>
          <Row gutter={16}>
            <Col md={12}>
              <Form.List name='licenses'>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                        <Form.Item
                          {...restField}
                          name={[name, 'type']}
                          rules={[{ required: true, message: 'Missing type' }]}
                        >
                          <Input size='large' prefix={<LinkOutlined />} placeholder='type legalitas' />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'no']}
                          rules={[{ required: true, message: 'Missing no' }]}
                        >
                          <Input size='large' placeholder='code legalitas' prefix={<LinkOutlined />} />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Col justify='end'>
                      <Button block type='primary' onClick={() => add()} icon={<PlusOutlined />}>
                        Add Legalitas
                      </Button>
                    </Col>
                  </>
                )}
              </Form.List>
              <Text style={!display ? { display: 'none' } : { color: 'red', paddingLeft: '.5rem', fontWeight: 400, fontSize: 12 }}>At least there must be legality at BPOM </Text>
            </Col>
            <Col md={12}>
              <Button
                block
                type='primary'
                onClick={() => setVisible(!visible)}
                icon={<PlusOutlined />}
              >
                Add Social Media
              </Button>
            </Col>
          </Row>
          <Gap height='1.6rem' />

          {category === 'fashion' &&
            <Row gutter={16} className='align-items-center'>
              <FormItem
                col={12}
                name='models'
                label='Models (Separate with commas)'
              />
              <FormItem
                col={12}
                name='sizes'
                label='Sizes (Separate with commas)'
              />
            </Row>}
          {category === 'fashion' &&
            <Row gutter={16} className='align-items-center'>
              <FormItem
                col={12}
                name='materials'
                label='Materials (Separate with commas)'
              />
              <FormItem
                col={12}
                name='colors'
                label='Colors (Separate with commas)'
              />
            </Row>}
          <Row>
            <Form.Item
              name='images'
              rules={[rules.required]}
              label='Upload Images Product'
            >
              <Upload
                customRequest={dummyRequest}
                listType='picture-card'
                beforeUpload={beforeUpload}
              >
                <CameraOutlined />
              </Upload>
            </Form.Item>
          </Row>
          <Row justify='end'>
            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
              icon={<PlusCircleOutlined />}
            >
              Submit
            </Button>
          </Row>
        </Card>
      </Forms>

      <PopUpFinished
        buttonRight='Check List Product'
        onClickCheckList={onClickCheckList}
        onClickCreateNew={onClickCreateNew}
        popupSuccess={popupSuccess}
      />

      <Modal
        onCancel={() => setVisible(!visible)}
        title='add social media'
        visible={visible}
        footer={false}
      >
        <Form
          layout='vertical'
          onFinish={onHandleSubmitSosmed}
          form={formSosmed}
        >
          <Form.List name='socials' rules={[rules.required]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                    <Form.Item
                      {...restField}
                      label={name < 1 && 'Name Social Media'}
                      name={[name, 'name']}
                      rules={[{ required: true, message: 'Missing name' }]}
                    >
                      {/* <Input prefix={<LinkOutlined />} placeholder='social media name' /> */}
                      <Select
                        style={{
                          width: 250
                        }}
                        placeholder='social media'
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: '8px 0'
                              }}
                            />
                            <Space
                              style={{
                                padding: '0 8px 4px'
                              }}
                            >
                              <Input
                                placeholder='Other'
                                ref={inputRef}
                                value={other}
                                onChange={onChangeOther}
                              />
                              <Button type='text' icon={<PlusOutlined />} onClick={addItem} className='d-flex align-items-center'>
                                Add
                              </Button>
                            </Space>
                          </>
                        )}
                        options={sosmedOption.map((item) => ({
                          label: item,
                          value: item
                        }))}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={name < 1 && 'URL'}
                      name={[name, 'url']}
                      rules={[{ required: true, message: 'Missing url' }]}
                    >
                      <Input prefix={<LinkOutlined />} placeholder='url or link social media' />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item style={{ padding: '0 .6rem' }}>
                  <Button
                    block
                    type='dashed'
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Row justify='end'>
            <AButton type='primary' htmlType='submit' title='Create' />
          </Row>
        </Form>
      </Modal>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerProductMaster }) => {
  return { ...reducerProductMaster }
}

export const CreateProduct = connect(mapStateToProps)(_CreateProduct)

_CreateProduct.propTypes = {
  popupSuccess: PropTypes.bool,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  token: PropTypes.string
}
