import { message } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { serviceCouponClaimers } from 'src/services/api/couponClaimers'

import { actions } from './actions'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'coupon_claimers/set_state',
    payload
  })
}

export function * getCouponClaimers ({ payload }) {
  yield load(true)

  const { token, params } = payload

  const [couponClaimers, count] = yield all([
    call(serviceCouponClaimers.get, token, params),
    call(serviceCouponClaimers.countUniquePhone, token, params)
  ])

  if (couponClaimers.status === 200 && count.status === 200) {
    const countmap = count.data.data.reduce((acc, item) => {
      acc[item.phone] = item._count.phone
      return acc
    }, {})

    const data = couponClaimers.data.data.items.map((item, index) => {
      return {
        ...item,
        count: countmap[item.phone]
      }
    })

    yield setState({
      data: data,
      meta: couponClaimers.data.data.meta,
      loading: false
    })
  } else {
    message.warning('Failed to fetch coupon claimers')

    yield load(false)
  }
}

export default function * rootSaga () {
  yield all([takeEvery(actions.get_coupon_claimers, getCouponClaimers)])
}
