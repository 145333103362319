import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Form, Modal, Row, Select } from 'antd'
import {
  TagsOutlined,
  PlusCircleOutlined,
  ProfileOutlined
} from '@ant-design/icons'

import { FormItem } from 'src/components/molecules/FormItem'
import { rules } from 'src/utils/rules'
import { countQRItem, limitScanItem } from 'src/utils/options'
import helper from 'src/services/helper'
import moment from 'moment'

export const _UpdateGenerateQR = ({
  success,
  dataUpdate,
  settings,
  product,
  distros,
  token,
  dispatch,
  loading,
  visible,
  onCancel
}) => {
  const [form] = Form.useForm()
  const [customCount, setCustomCount] = useState(false)
  const [detailGenerate, setDetailGenerate] = useState()
  const dataDisplayed = [
    {
      value: 'full',
      name: 'Full Data'
    },
    {
      value: 'simple',
      name: 'Simple Data'
    }
  ]

  const exportAs = [
    {
      value: 'excel',
      name: 'Excel (spreadsheets)'
    },
    {
      value: 'pdf',
      name: 'PDF'
    }
  ]

  useEffect(() => {
    dataUpdate && getDetailGenerate(dataUpdate?.id)

    const newData = {
      ...form,
      productionCode: dataUpdate?.productionCode,
      productId: dataUpdate?.product?.id,
      total: dataUpdate?._count?.qrcodes,
      productionDate: moment(dataUpdate?.productionDate),
      batchSize: Number(dataUpdate?.batchSize),
      displayMode: dataUpdate?.displayMode
    }

    form.setFieldsValue(newData)
  }, [dataUpdate])

  useEffect(() => {
    const distroIds = []
    if (detailGenerate?.distros) {
      for (const item of detailGenerate?.distros) {
        distroIds.push(item.id)
      }
    }

    const newData = {
      ...form,
      distroIds: distroIds,
      exportAs:
        detailGenerate?.qrcodes?.[0]?.url === ''
          ? exportAs[0].value
          : exportAs[1].value,
      limitCheck: detailGenerate?.qrcodes?.[0]?.limitCheck || 'Unlimited',
      customCode: detailGenerate?.customCode
    }

    form.setFieldsValue(newData)
  }, [detailGenerate])

  useEffect(() => {
    loading && form.resetFields()
  }, [loading])

  const getDetailGenerate = (state) => {
    helper.get(`api/v1/qrcodes/${state}`, token).then((res) => {
      const datas = res.data.data
      const qrcodes = datas.qrcodes
      if (qrcodes) {
        datas.qrcodes = qrcodes.sort(
          (a, b) =>
            Number(
              a.sku.substring(a.sku.indexOf('-') + 1, a.sku.lastIndexOf('-'))
            ) -
            Number(
              b.sku.substring(b.sku.indexOf('-') + 1, b.sku.lastIndexOf('-'))
            )
        )
      }

      setDetailGenerate(datas)
    })
  }

  const onHandleUpdateGenerateQR = () => {
    form.validateFields().then((data) => {
      if (data.total === 'Custom') data.total = data.customCountQR
      if (data.limitCheck === 'Unlimited - unique id') {
        delete data.limitCheck
      }
      if (data.distroIds.length === 0) {
        delete data.distroIds
      }

      delete data.total
      delete data.batchSize
      delete data.exportAs

      const items = {
        ...data,
        limitCheck: Number(data.limitCheck)
      }

      dispatch({
        type: 'generate/put_update_generate_qr',
        payload: {
          token,
          items,
          generateId: dataUpdate?.id
        }
      })
    })

    setTimeout(() => {
      onCancel()
    }, 400)
  }

  const showCustomCountQr = (val) => {
    setCustomCount(val === 'Custom')
  }

  return (
    <Modal
      title={`Edit Generate QR: ${dataUpdate?.customId}`}
      visible={visible}
      footer={false}
      onCancel={onCancel}
      width={800}
      forceRender
    >
      <Form form={form} onFinish={onHandleUpdateGenerateQR} layout='vertical'>
        <FormItem
          label='Production Code'
          placeholder='input your production code'
          name='productionCode'
          prefix={<TagsOutlined />}
          rules={[rules.required]}
        />
        <Row>
          <FormItem
            label='Distro'
            placeholder='Choose Agent'
            name='distroIds'
            select
            mode='multiple'
          >
            {distros?.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </FormItem>
        </Row>
        <Row gutter={16}>
          <FormItem
            col={12}
            label='Product'
            placeholder='Choose Product'
            name='productId'
            select
            rules={[rules.required]}
          >
            {product?.map((item, index) => (
              <Select.Option key={index} value={item?.id}>
                {item?.pending === true
                  ? item?.name + ' (pending)'
                  : item?.name}
              </Select.Option>
            ))}
          </FormItem>
          <FormItem
            label='Count QR'
            placeholder='Count QR'
            name='total'
            select
            col={6}
            rules={[rules.required]}
            onChange={(e) => showCustomCountQr(e)}
            disabled
          >
            {countQRItem?.map((item, index) => (
              <Select.Option key={index} value={item}>
                {item}
              </Select.Option>
            ))}
          </FormItem>
          <FormItem
            label='Batch Size'
            placeholder='Batch Size'
            name='batchSize'
            number
            col={6}
            prefix={<ProfileOutlined />}
            rules={[rules.required, rules.numeric, rules.maxValue(2000)]}
            disabled
          />
        </Row>
        {customCount && (
          <Row gutter={16}>
            <FormItem
              label='Custom Count QR'
              placeholder='input Count QR'
              name='customCountQR'
              rules={[rules.required]}
            />
          </Row>
        )}
        <Row gutter={16}>
          <FormItem
            col={12}
            label='Limit Scan'
            placeholder='Limit Scan'
            name='limitCheck'
            select
            rules={[rules.required]}
          >
            {limitScanItem?.map((item, index) => (
              <Select.Option key={index} value={item}>
                {item}
              </Select.Option>
            ))}
          </FormItem>
          <FormItem
            col={12}
            label='Setting QR'
            placeholder='Choose name setting QR'
            name='qrCodeSettingId'
            select
            rules={[rules.required]}
          >
            {settings?.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </FormItem>
        </Row>
        <Row gutter={16}>
          <FormItem
            col={12}
            date
            label='Production Date'
            name='productionDate'
            rules={[rules.required]}
          />
          <FormItem
            col={12}
            label='Data to be displayed'
            placeholder='Choose display mode'
            name='displayMode'
            select
            rules={[rules.required]}
          >
            {dataDisplayed?.map((item, index) => (
              <Select.Option key={index} value={item.value}>
                {item.name}
              </Select.Option>
            ))}
          </FormItem>
        </Row>
        <Row gutter={16}>
          <FormItem
            col={12}
            label='Code'
            name='customCode'
            placeholder='Input your custom code'
            inputStyle={{ padding: '10px' }}
          />
          <FormItem
            col={12}
            label='Export as'
            placeholder='Choose'
            name='exportAs'
            select
            rules={[rules.required]}
            disabled
          >
            {exportAs?.map((item, index) => (
              <Select.Option key={index} value={item.value}>
                {item.name}
              </Select.Option>
            ))}
          </FormItem>
        </Row>
        <Row justify='end'>
          <Button
            type='primary'
            htmlType='submit'
            loading={loading}
            icon={<PlusCircleOutlined />}
          >
            Submit
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}

const mapStateToProps = ({ reducerGenerateQR }) => {
  return { ...reducerGenerateQR }
}

export const UpdateGenerateQR = connect(mapStateToProps)(_UpdateGenerateQR)

_UpdateGenerateQR.propTypes = {
  loading: PropTypes.bool,
  product: PropTypes.array,
  success: PropTypes.bool,
  distros: PropTypes.array,
  settings: PropTypes.array,
  token: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  dispatch: PropTypes.func,
  dataUpdate: PropTypes.object
}
